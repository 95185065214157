<template>
  <div class="PersonContainer">
    <div class="backgroundImg">
      <img :src="backgroundImage" alt="" />
      <div class="personalHead">
        <div>
          <img :src="userInfo.avatar" alt="" />
        </div>
      </div>
    </div>

    <div class="personalHeader">
      <div class="follow">
        <div v-show="userId != $store.getters.getUser.id">
          <van-button
            @click="Follow"
            icon="plus"
            type="info"
            round
            v-show="!isFollow"
            >关注</van-button
          >
          <van-button
            @click="unFollow"
            plain
            type="info"
            round
            v-show="isFollow"
            >已关注</van-button
          >
        </div>
      </div>
    </div>

    <div class="personalName">
      <div class="person-id">{{ userInfo.nickname }}</div>

      <div class="person-date">
        <van-icon name="calendar-o" size="15" />
        Joined {{ createDate }}
      </div>

      <div class="person-follow">
        <span style="font-weight: bold"> {{ userInfo.following }} </span>

        <div>Following</div>

        <span style="font-weight: bold"> {{ userInfoFollower }} </span>

        <div>Followers</div>
      </div>
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了~"
        @load="onLoad"
        class="user-poster"
        id="user-poster"
      >
        <van-cell
          class="user-root"
          @click="goDetail(index)"
          v-for="(p, index) in list"
          :key="index"
        >
          <!-- 用户头像和id -->
          <div class="header">
            <div class="user-head">
              <div>
                <img :src="userInfo.avatar" alt="" />
              </div>
            </div>

            <div class="user-name">
              <div class="user-id">{{ userInfo.nickname }}</div>
              <div class="user-time">{{ p.created }}</div>
            </div>
          </div>

          <div>
            <div class="user-content">
              <div @click="goSearch(p.difficulty)" style="color: dodgerblue">
                #{{ p.difficulty }}
              </div>
              <div
                @click="goSearch(p.location)"
                style="color: dodgerblue; margin-left: 8px"
              >
                #{{ p.location }}
              </div>
            </div>
            <div v-show="p.content" class="user-content">
              {{ p.content }}
            </div>

            <div class="user-photo">
              <img :src="p.pic" alt="" @click.stop="getImage(index)" />
            </div>

            <div class="icons1">
              <div style="display: flex">
                <van-icon
                  @click.stop="CollectRoute(p, index)"
                  v-show="!temp[index]"
                  name="star-o"
                  size="21"
                />
                <van-icon
                  @click.stop="unCollectRoute(p, index)"
                  v-show="temp[index]"
                  color="#FFD700"
                  name="star"
                  size="21"
                />
                <span v-show="!p.collectCounts">收藏</span>
                <span style="font-size: 15px" v-show="p.collectCounts">
                  {{ p.collectCounts }}
                </span>
              </div>

              <div style="display: flex">
                <van-icon style="margin-top: 2px" name="comment-o" size="21" />
                <span v-show="!p.commentCounts"> 评论 </span>
                <span style="font-size: 15px" v-show="p.commentCounts">
                  {{ p.commentCounts }}
                </span>
              </div>

              <div style="display: flex">
                <van-icon
                  @click.stop="LikeRoute(p, index)"
                  v-show="!temp1[index]"
                  name="good-job-o"
                  size="22"
                />
                <van-icon
                  @click.stop="unLikeRoute(p, index)"
                  v-show="temp1[index]"
                  color="#e93855"
                  name="good-job"
                  size="22"
                />
                <span v-show="!p.likesCounts">点赞</span>
                <span style="font-size: 15px" v-show="p.likesCounts">
                  {{ p.likesCounts }}
                </span>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      isFollow: false,
      userInfoFollower: 0,
      userInfo: {},
      temp: [],
      temp1: [],
      likes: [],
      loading: false,
      finished: false,
      refreshing: false,
      collections: [],
      collectionId: [],
      userId: "",
      posterInfo: [],
      list: [],
      k: 0,
      t: 5,
      backgroundImage: require("@/assets/upload/panyan3-1.png"),
    };
  },

  methods: {
    unFollow() {
      Dialog.confirm({
        confirmButtonColor: "#0099FF",
        cancelButtonColor: "#0099FF",
        message: "是否取消关注",
      })
        .then(() => {
          this.isFollow = !this.isFollow;

          this.$axios
            .post("/follow/cancelFollow", {
              userId: this.userId,
              followerId: this.$store.getters.getUser.id,
            })
            .then((res) => {
              //减少被关注用户的被关注人数
              this.cutFollowerCount();
              //减少用户的关注人数
              this.cutFollowingCount();
            });
        })
        .catch(() => {});
    },

    cutFollowerCount() {
      this.$axios.post("/user/cutFollower/" + this.userId);
      this.userInfoFollower = this.userInfoFollower - 1;
    },

    cutFollowingCount() {
      this.$axios
        .post("/user/cutFollowing/" + this.$store.getters.getUser.id)
        .then((res) => {
          this.$store.commit("SET_USERINFO", res.data.data);
        });
    },

    Follow() {
      this.isFollow = !this.isFollow;
      this.$axios
        .post(
          "/follow/addFollow",
          {
            userId: this.userId,
            followerId: this.$store.getters.getUser.id,
          },
          {
            headers: { Authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          //添加被关注用户的被关注人数
          this.addFollowerCount();
          //添加用户的关注人数
          this.addFollowingCount();
        });
    },

    addFollowerCount() {
      this.$axios.post("/user/addFollower/" + this.userId);
      this.userInfoFollower = this.userInfoFollower + 1;
    },

    addFollowingCount() {
      this.$axios
        .post("/user/addFollowing/" + this.$store.getters.getUser.id)
        .then((res) => {
          this.$store.commit("SET_USERINFO", res.data.data);
        });
    },

    isFollowed() {
      this.$axios
        .post("/follow/isFollow", {
          userId: this.userId,
          followerId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.isFollow = res.data.data;
        });
    },

    getImage(index) {
      ImagePreview([this.posterInfo[index].pic]);
    },
    goDetail(index) {
      this.$router.push({
        name: "detail",
        params: {
          posterId: this.posterInfo[index].id,
        },
      });
    },
    getUploading(id) {
      this.$axios.get("/blogs/" + id).then((res) => {
        this.posterInfo = res.data.data;
        this.posterInfo.reverse();
        this.onLoad();
      });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.k = 0;
          this.t = 5;
          this.refreshing = false;
        }
        if (this.posterInfo.length < this.t) {
          this.list = this.posterInfo;
        } else {
          for (let i = this.k; i < this.k + 5; i++) {
            this.list.push(this.posterInfo[i]);
          }

          this.k = this.k + 5;
          this.t = this.t + 5;
        }

        this.loading = false;
        if (this.list.length >= this.posterInfo.length) {
          this.finished = true;
        }

        //收藏

        this.temp = [];
        this.list.forEach((a) => {
          if (this.collections.length == 0) {
            this.temp.push(false);
          }

          for (let k = 0; k < this.collections.length; k++) {
            const b = this.collections[k];
            if (a.id == b.articleId) {
              this.temp.push(true);
              break;
            } else if (k == this.collections.length - 1) {
              this.temp.push(false);
            }
          }
        });

        //点赞

        this.temp1 = [];
        this.list.forEach((a) => {
          if (this.likes.length == 0) {
            this.temp1.push(false);
          }
          for (let k = 0; k < this.likes.length; k++) {
            const b = this.likes[k];
            if (a.id == b.articleId) {
              this.temp1.push(true);
              break;
            } else if (k == this.likes.length - 1) {
              this.temp1.push(false);
            }
          }
        });
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    collectCount(p) {
      p.collectCounts = p.collectCounts + 1;
      this.$axios.post("/blog/editComments", {
        id: p.id,
        commentCounts: p.commentCounts,
        collectCounts: p.collectCounts,
        likeCounts: p.likesCounts,
      });
    },

    CollectRoute(p, index) {
      this.isCollect = true;
      this.$axios
        .post(
          "/collection/addCollect",
          {
            articleId: p.id,
            userId: this.$store.getters.getUser.id,
          },
          {
            headers: { Authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          Toast("收藏成功！");
          this.collectCount(p);
          this.temp[index] = true;
        });
    },

    unCollectCount(p) {
      this.isCollect = false;
      p.collectCounts = p.collectCounts - 1;
      this.$axios.post("/blog/editComments", {
        id: p.id,
        commentCounts: p.commentCounts,
        collectCounts: p.collectCounts,
        likesCounts: p.likesCounts,
      });
    },

    unCollectRoute(p, index) {
      this.$axios
        .post("/collection/cancelCollect", {
          articleId: p.id,
          userId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.unCollectCount(p);
          Toast("已取消");
          this.temp[index] = false;
        });
    },

    getCollects(user_id) {
      this.$axios.get("/collection/collects/" + user_id).then((res) => {
        this.collections = res.data.data;
      });
    },

    likeCount(p) {
      p.likesCounts = p.likesCounts + 1;
      this.$axios
        .post("/blog/editComments", {
          id: p.id,
          commentCounts: p.commentCounts,
          collectCounts: p.collectCounts,
          likesCounts: p.likesCounts,
        })
        .then((res) => {
          console.log("点赞完后的posterinfo", this.posterInfo);
        });
    },

    LikeRoute(p, index) {
      this.isLike = !this.isLike;
      this.$axios
        .post(
          "/like/addLike",
          {
            articleId: p.id,
            userId: this.$store.getters.getUser.id,
          },
          {
            headers: { Authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          this.likeCount(p);
          this.temp1[index] = !this.temp1[index];
          Toast("已点赞");
        });
    },

    unLikeRoute(p, index) {
      this.isLike = !this.isLike;
      this.$axios
        .post("/like/cancelLike", {
          articleId: p.id,
          userId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.unLikeCount(p);
          Toast("已取消");
          this.temp1[index] = !this.temp1[index];
        });
    },

    unLikeCount(p) {
      p.likesCounts = p.likesCounts - 1;
      this.$axios.post("/blog/editComments", {
        id: p.id,
        commentCounts: p.commentCounts,
        collectCounts: p.collectCounts,
        likesCounts: p.likesCounts,
      });
    },

    isLiked(user_id) {
      this.$axios.get("/like/likes/" + user_id).then((res) => {
        this.likes = res.data.data;
      });
    },

    getInfo(id) {
      this.$axios.get("/user/users/" + id).then((res) => {
        this.userInfo = res.data.data;
        console.log(this.userInfo);
        this.userInfoFollower = this.userInfo.follower;
      });
    },
  },

  computed: {
    createDate() {
      if (this.userInfo.created) {
        var a = this.userInfo.created.split("T")[0];
        var arr = a.split("-");
        var timeStr = arr[0] + "年" + Number(arr[1]) + "月" + arr[2] + "日";
        return timeStr;
      }
    },
  },

  mounted() {
    this.userId = this.$route.params.userId;
    this.getInfo(this.userId);
    this.isFollowed();
    this.getUploading(this.userId);
    this.isLiked(this.userId);
    this.getCollects(this.userId);
  },
};
</script>

<style scoped>
.PersonContainer {
  border: 5px solid rgb(0, 0, 0);
  background-color:white ;
}

.backgroundImg {
  position: relative;
  width: 100%;
  /* position: absolute; */
}

.backgroundImg img {
  width: 100%;
}

.personalHead {
  position: absolute;
  bottom: -53px;
  left: 20px;
  width: 70px;
  height: 70px;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  z-index: 20;
}

.personalHead img {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.personalHeader {
  position: relative;
  z-index: 50;
}

.follow {
  align-items: center;
  width: 100%;
  height: 48px;
}

.follow button {
  margin: 10px;

  float: right;
  width: 100px;
  height: 38px;
  line-height: 30px;
}

.personalName {
  width: 100%;
  padding-top: 12px;
  padding-left: 25px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.person-id {
  color: black;
  font-weight: 600;
  font-size: 22px;
}

.person-date {
  color: rgb(85, 91, 109);
  font-size: 10px;
  padding-top: 8px;
}

.person-follow {
  display: flex;
  font-size: 10px;
  padding-top: 8px;
}

.person-follow div {
  color: rgb(62, 67, 86);
  margin-left: 4px;
  margin-right: 10px;
}

.user-poster {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: white;
  color: black;
}

.user-root {
  /* border-radius: 25px; */
  overflow: hidden;
  border-top: solid 0.5px #e6e6e6;
}

.header {
  display: flex;
  height: 45px;
}

.van-popover--light {
  color: rgb(255, 0, 0) !important;
}

.user-head {
  position: absolute;
  margin: 0;
  width: 45px;
  height: 45px;
  /* background-color: rgb(68, 138, 138); */
}

.user-head img {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.user-name {
  position: absolute;
  left: 58px;
  color: black;
  font-weight: 700;
  font-size: 15px;
}
.user-time {
  color: #b7b7b7;
  font-size: 12px;
  margin: 2px;
}

.user-content {
  margin-left: 61px;
  margin-top: 4px;
  color: black;
  width: 85%;
  float: left;
  font-size: 14px;
  display: flex;
  /* background-color: yellow; */
}

.user-content div {
  position: relative;
  float: left;
}

.user-contents {
  margin-left: 63px;
  margin-bottom: 10px;
  color: black;
  width: 85%;
  font-size: 16px;
  display: flex;
  /* background-color: yellow; */
}

.user-photo {
  display: flex;
  margin-left: 60px;
  margin-bottom: 10px;
  width: 82%;

  /* background-color: wheat; */
}

.user-photo img {
  border: 0.5px solid rgb(168, 168, 168);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.search-login a {
  text-decoration: none;
  color: black;
}

.icons1 {
  position: relative;
  z-index: 10;
  display: flex;
  height: 30px;
  margin: 0 auto;
  width: 85%;
  display: flex;
  right: 0;
}

.icons1 div {
  width: 33%;
  margin: 5px 0px 0px 20px;
}

.icons1 i {
  margin-left: 10px;
  position: absolute;
  z-index: 20;
}

.icons1 span {
  position: absolute;
  z-index: 40;
  margin-left: 40px;
}
</style>